<template>
  <div style="width: 100%" id="printReport">
    <header
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      "
    >
      <img
        src="@/assets/logoBlue.png"
        alt="[logo]"
        style="width: 600px; padding: 20px"
      />
      <div>
        <h5>
          {{ `RELATÓRIO SINTÉTICO` }}
        </h5>
        <p>
          Período de: {{ convertDate(dateStart) }} à {{ convertDate(dateEnd) }}
        </p>
      </div>
    </header>

    <table
      v-for="(item, ind) in items"
      :key="ind"
      style="text-align: center; width: 100%; margin-top: 40px"
    >
      <thead>
        <tr>
          <th
            style="padding: 5px; display: flex; justify-content: space-between"
          >
            <div>
              {{ item.agencia }}
            </div>
            <div>Total: {{ convertCurrent(item.total) }}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <table
              class="table"
              style="
                border-collapse: collapse;
                border: 1px solid rgb(214, 214, 214);
                border-top: 0;
                text-align: center;
                width: 100%;
              "
            >
              <thead>
                <tr
                  style="
                    border: 1px solid rgb(214, 214, 214);
                    text-align: center;
                  "
                >
                  <th
                    style="border: 1px solid rgb(214, 214, 214); padding: 5px"
                  >
                    {{ headers[0].text.toUpperCase() }}
                  </th>
                  <th
                    style="border: 1px solid rgb(214, 214, 214); padding: 5px"
                  >
                    {{ headers[1].text.toUpperCase() }}
                  </th>
                </tr>
              </thead>

              <tbody
                v-for="(data, idx) in [
                  { tipo: 'Passagens', total: item.passagem },
                  { tipo: 'Excesso de Bagagem', total: item.excesso },
                  { tipo: 'Encomendas Pagas', total: item.encomendasPagas },
                  { tipo: 'Encomendas A Pagar', total: item.encomendasAPagar },
                ]"
                :key="idx"
              >
                <tr style="border: 1px solid rgb(214, 214, 214)">
                  <td style="border: 1px solid rgb(214, 214, 214)">
                    {{ data.tipo }}
                  </td>
                  <td>
                    {{ convertCurrent(data.total) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import mixin from "@/mixins";
export default {
  props: ["items", "headers", "dateStart", "dateEnd", "agencia"],
  mixins: [mixin],
  create() {
    this.mixin;
  },
};
</script>
